import { createContext } from 'react';
import { SendbirdGroupChat } from '@sendbird/chat/groupChannel';

interface Props {
  sendbird: SendbirdGroupChat;
}

export const AppContext = createContext({} as Props);
export const WithAppContext = (Component: any, mapStateToProp: any = null) => {
  const ConsumableComponent = (props: any) => {
    return (
      <AppContext.Consumer>
        {(state) => {
          const mappedProps = mapStateToProp ? mapStateToProp(state) : state;
          const mergedProps = { ...props, ...mappedProps };
          return <Component {...mergedProps} />;
        }}
      </AppContext.Consumer>
    );
  };
  return ConsumableComponent;
};
