import { createSlice } from '@reduxjs/toolkit';

import { changeLanguage } from 'translate/i18next/i18next';

const initialState: {
  rememberId: null | string;
  sidebarOpened: boolean;
  language: string;
  submitCardFold: boolean;
} = {
  rememberId: null,
  sidebarOpened: true,
  language: 'en',
  submitCardFold: false,
};

export const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
    SetRememberId: (state, action) => {
      return { ...state, rememberId: action.payload };
    },
    SetSidebarState: (state) => {
      return { ...state, sidebarOpened: !state.sidebarOpened };
    },
    SetLanguage: (state, action) => {
      changeLanguage(action.payload);
      return { ...state, language: action.payload };
    },
    SetSubmitCardFold: (state, action) => {
      return { ...state, submitCardFold: action.payload };
    },
  },
});

export const { SetRememberId, SetSidebarState, SetLanguage, SetSubmitCardFold } = optionSlice.actions;

export default optionSlice.reducer;
