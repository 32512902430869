import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { userType } from 'utils/Rules/Account/accessLevel';

import PageLoading from 'components/Elements/Loading/PageLoading';
import {
  BannerDetailPage,
  BannerListPage,
  ChatPage,
  DashboardMainPage,
  EditBannerPage,
  EditUserPage,
  CampaignCreatePage,
  CampaignListPage,
  CampaignEditPage,
  CampaignDetailPage,
  CampaignGuidePage,
  LoginPage,
  NewBannerPage,
  NewUserPage,
  SettingsMainPage,
  UserDetailPage,
  UsersMainPage,
  UserChargeHistoryPage,
  UserSpendHistoryPage,
  MailAuthPage,
  MyJellyPage,
  JellyChargeHistoryPage,
  JellySpendHistoryPage,
  PackageSettingPage,
  ClientListPage,
  ClientDetailPage,
  NewClientPage,
  EditClientPage,
  ClientChargeHistoryPage,
  ClientSpendHistoryPage,
  ClientOrderHistoryPage,
  OrderHistoryPage,
  AdminOrderHistoryPage,
  PayoutRequestListPage,
  PayoutRequestDetailPage,
  NoticeListPage,
  NoticeDetailPage,
  EditNoticePage,
  NewNoticePage,
  AnalyticsPage,
  NotificationsListPage,
  NotificationsCreatePage,
  NotificationsDetailPage,
  NotificationsEditPage,
  ChatAdminPage,
} from 'pages';
import ErrorBoundary from 'router/ErrorBoundary';

import { selectAccount } from 'features/Redux/selectors/accounts';

export default function Router() {
  const account = useSelector(selectAccount);
  const { userInfo, isLogin } = account;
  const { user } = userInfo;

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense fallback={<PageLoading />}>
          <Routes>
            {!isLogin ? (
              <>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/account/mail/:key" element={<MailAuthPage />} />
                <Route path="*" element={<Navigate replace to="/login" />} />
              </>
            ) : (
              <>
                <Route path="/" element={<DashboardMainPage />} />
                <Route path="/chat" element={<ChatPage />} />

                <Route path="/list/:campaign" element={<CampaignListPage />} />
                <Route path="/detail/:campaign/:id" element={<CampaignDetailPage />} />
                <Route path="/detail/:campaign/:type/:id" element={<CampaignDetailPage />} />
                <Route path="/add/:campaign/" element={<CampaignCreatePage />} />
                <Route path="/add/:campaign/:type" element={<CampaignCreatePage />} />
                <Route path="/edit/:campaign/:id" element={<CampaignEditPage />} />
                <Route path="/edit/:campaign/:type/:id" element={<CampaignEditPage />} />

                <Route path="/guide/campaign/:campaign" element={<CampaignGuidePage />} />

                {userType(user) === 'super' ? (
                  <>
                    <Route path="/banner" element={<BannerListPage />} />
                    <Route path="/banner/:id" element={<BannerDetailPage />} />
                    <Route path="/banner/:id/edit" element={<EditBannerPage />} />
                    <Route path="/banner/new" element={<NewBannerPage />} />

                    {user.username === 'nurihaus_admin' && <Route path="/chat/admin" element={<ChatAdminPage />} />}

                    <Route path="/users" element={<UsersMainPage />} />
                    <Route path="/users/:id" element={<UserDetailPage />} />
                    <Route path="/users/:id/edit" element={<EditUserPage />} />
                    <Route path="/users/new" element={<NewUserPage />} />
                    <Route path="/users/:id/charge-history" element={<UserChargeHistoryPage />} />
                    <Route path="/users/:id/spend-history" element={<UserSpendHistoryPage />} />

                    <Route path="/client" element={<ClientListPage />} />
                    <Route path="/client/:id" element={<ClientDetailPage />} />
                    <Route path="/client/:id/:pointType/charge-history" element={<ClientChargeHistoryPage />} />
                    <Route path="/client/:id/:pointType/spend-history" element={<ClientSpendHistoryPage />} />
                    <Route path="/client/:id/:pointType/order-history" element={<ClientOrderHistoryPage />} />
                    <Route path="/client/:id/edit" element={<EditClientPage />} />
                    <Route path="/client/new" element={<NewClientPage />} />

                    <Route path="/point/:pointType" element={<MyJellyPage />} />
                    <Route path="/point/:pointType/admin-order-history" element={<AdminOrderHistoryPage />} />
                    <Route path="/point/:pointType/package-setting/:id" element={<PackageSettingPage />} />

                    <Route path="/payout" element={<PayoutRequestListPage />} />
                    <Route path="/payout/detail/:id" element={<PayoutRequestDetailPage />} />

                    <Route path="/notice" element={<NoticeListPage />} />
                    <Route path="/notice/:slug" element={<NoticeDetailPage />} />
                    <Route path="/notice/:slug/edit" element={<EditNoticePage />} />
                    <Route path="/notice/new" element={<NewNoticePage />} />

                    <Route path="/analytics" element={<AnalyticsPage />} />

                    <Route path="/notifications" element={<NotificationsListPage />} />
                    <Route path="/notifications/detail/:id" element={<NotificationsDetailPage />} />
                    <Route path="/notifications/create" element={<NotificationsCreatePage />} />
                    <Route path="/notifications/edit/:id" element={<NotificationsEditPage />} />
                  </>
                ) : userType(user) === 'partner' ? (
                  <>
                    <Route path="/banner" element={<BannerListPage />} />
                    <Route path="/banner/:id" element={<BannerDetailPage />} />
                    <Route path="/banner/:id/edit" element={<EditBannerPage />} />
                    <Route path="/banner/new" element={<NewBannerPage />} />

                    <Route path="/users" element={<UsersMainPage />} />
                    <Route path="/users/:id" element={<UserDetailPage />} />
                    <Route path="/users/:id/charge-history" element={<UserChargeHistoryPage />} />
                    <Route path="/users/:id/spend-history" element={<UserSpendHistoryPage />} />

                    <Route path="/settings" element={<SettingsMainPage />} />

                    <Route path="/notifications" element={<NotificationsListPage />} />
                    <Route path="/notifications/detail/:id" element={<NotificationsDetailPage />} />
                    <Route path="/notifications/create" element={<NotificationsCreatePage />} />
                    <Route path="/notifications/edit/:id" element={<NotificationsEditPage />} />
                  </>
                ) : userType(user) === 'brand' ? (
                  <>
                    <Route path="/point/:pointType" element={<MyJellyPage />} />
                    <Route path="/point/:pointType/charge-history" element={<JellyChargeHistoryPage />} />
                    <Route path="/point/:pointType/spend-history" element={<JellySpendHistoryPage />} />
                    <Route path="/point/:pointType/order-history" element={<OrderHistoryPage />} />

                    <Route path="/settings" element={<SettingsMainPage />} />
                  </>
                ) : (
                  <></>
                )}

                <Route path="*" element={<Navigate replace to="/" />} />
              </>
            )}
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
