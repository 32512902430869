import { ErrorInfo, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import StyledText from 'components/Elements/Text/StyledText';

interface IProps {
  children: ReactNode;
}
interface IErrorBoundaryState {
  hasError: boolean;
  error?: Error | null;
  errorInfo?: ErrorInfo | null;
}

export default function ErrorBoundary({ children }: IProps) {
  const [errorState, setErrorState] = useState<IErrorBoundaryState>({
    hasError: false,
    error: null,
    errorInfo: null,
  });

  useEffect(() => {
    const handleError = (error: ErrorEvent) => {
      setErrorState((prevState) => ({
        ...prevState,
        hasError: true,
        error: error.error || null,
        errorInfo: error.error ? error.error.stack || null : null,
      }));
    };

    window.addEventListener('error', handleError);
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);

  if (errorState.hasError) {
    return (
      <ErrorContainer>
        <StyledText fontWeight="bold" fontSize="xl2" text="Something went wrong" textAlign="center" />
        {errorState.error && <StyledText text={errorState.error.toString()} />}
        {errorState.errorInfo && <StyledText text={errorState.errorInfo.componentStack} />}
      </ErrorContainer>
    );
  }

  return <>{children}</>;
}

const ErrorContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
