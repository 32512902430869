import { createSlice } from '@reduxjs/toolkit';

import { Country } from 'types/account';
import { Currency } from 'types/payout';

const initialState: {
  country: Country[];
  currency: Currency[];
} = {
  country: [],
  currency: [],
};

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    StoreCountryList: (state, action) => {
      return {
        ...state,
        country: action.payload,
      };
    },
    StoreCurrencyList: (state, action) => {
      return {
        ...state,
        currency: action.payload,
      };
    },
  },
});

export const { StoreCountryList, StoreCurrencyList } = addressSlice.actions;

export default addressSlice.reducer;
