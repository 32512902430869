import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'features/Redux/store';

export const selectAccount = (state: RootState) => state.account;

export const selectUser = (state: RootState) => state.account.userInfo.user;

export const selectAccessToken = (state: RootState) => state.account.userInfo.access_token;

export const userInfoSelector = createSelector(selectAccount, (state) => state);
