import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import customStorage from 'features/Redux/customStore';

import accountReducer from 'features/Redux/slices/accountSlice';
import optionReducer from 'features/Redux/slices/optionSlice';
import snackbarReducer from 'features/Redux/slices/snackbarSlice';
import addressReducer from 'features/Redux/slices/addressSlice';
import commonReducer from 'features/Redux/slices/commonSlice';

const persistConfig = {
  key: 'root',
  storage: customStorage,
};

const rootReducer = combineReducers({
  account: accountReducer,
  option: optionReducer,
  snackbar: snackbarReducer,
  address: addressReducer,
  common: commonReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: true,
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
