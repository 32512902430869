import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  guideLink: string;
} = {
  guideLink: '',
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    getGuideLink: (state, action) => {
      return { ...state, guideLink: action.payload.link };
    },
  },
});

export const { getGuideLink } = commonSlice.actions;

export default commonSlice.reducer;
