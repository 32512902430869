import CryptoJS from 'crypto-js';

const privateKey = process.env.REACT_APP_CRYPTO_SECRET_KEY || '';

export const encrypto = <T = any>(data: T) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), privateKey).toString();
};

export const decrypto = <T = any>(data: T) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, privateKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    // console.log('Decryption error :', error);
    return null;
  }
};
