import { requestWithoutAuth } from 'utils/Axios-utils';

import { getGuideLink } from 'features/Redux/slices/commonSlice';

export const getBannerDataById = async (id: number, dispatch: any) => {
  return await requestWithoutAuth({ method: 'get', url: `/contents/slider/${id}/` }).then((res) => {
    return dispatch(getGuideLink(res));
  });
};

export const getBannerDataBySlug = async (slug: string, dispatch: any) => {
  return await requestWithoutAuth({ method: 'get', url: `/contents/slider/i18n/${slug}/` }).then((res) => {
    if (!res?.id) throw new Error('no data');
    return dispatch(getGuideLink(res));
  });
};
