import SendbirdChat from '@sendbird/chat';
import { GroupChannelModule, SendbirdGroupChat } from '@sendbird/chat/groupChannel';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AppContext } from 'features/Redux/context';
import { persistor, store } from 'features/Redux/store';
import { ThemeProvider } from 'styles/theme/ThemeProvider';
import { OverlayProvider } from './overlay-provider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});
const appId = process.env.REACT_APP_SENDBIRD_APP_ID as string;

const params = {
  appId,
  modules: [new GroupChannelModule()],
};

const sendbird = SendbirdChat.init(params);

const initialState: { sendbird: SendbirdGroupChat } = {
  sendbird: sendbird as SendbirdGroupChat,
};

export const AppProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <OverlayProvider>
            <AppContext.Provider value={initialState}>
              <ThemeProvider>{children}</ThemeProvider>
            </AppContext.Provider>
          </OverlayProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};
