import storage from 'redux-persist/lib/storage';

import { encrypto, decrypto } from 'utils/Function/cryptoUtils';

const customStorage = {
  getItem: async (key: string) => {
    const data = await storage.getItem(key);
    if (!data) return data;
    return decrypto(data);
  },
  setItem: async <T = any>(key: string, value: T) => {
    return await storage.setItem(key, encrypto(value));
  },
  removeItem: async (key: string) => {
    return await storage.removeItem(key);
  },
};

export default customStorage;
