/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCountryList } from 'api/Account';
import { initializeI18next } from 'translate/i18next/i18next';
import { getAdminGuideLink } from 'utils/Function/Link';

import { selectAccount } from 'features/Redux/selectors/accounts';
import Router from 'router/Router';
import { selectOption } from 'features/Redux/selectors/options';
import { SetLanguage } from 'features/Redux/slices/optionSlice';
import { getCurrencyList } from 'api/Account/Address';

function App() {
  const { language } = useSelector(selectOption);
  const account = useSelector(selectAccount);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!account.isLogin || !account.userInfo.access_token) return;
    getCountryList(dispatch, account.userInfo.access_token);
    getCurrencyList(account.userInfo.access_token, dispatch);
  }, [account.isLogin]);

  useEffect(() => {
    if (language) {
      initializeI18next(language);
      dispatch(SetLanguage(language));
    } else {
      initializeI18next('en');
      dispatch(SetLanguage('en'));
    }
    getAdminGuideLink(language, dispatch);
  }, [language]);

  return <Router />;
}

export default App;
