import styled from 'styled-components';

import { color } from 'styles/assets';

export const Row = styled.div<{
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  gap?: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  align-items: ${(props) => props.alignItems || 'center'};
  gap: ${(props) => props.gap || '0'};
`;

export const Column = styled.div<{ gap?: string; alignItems?: 'flex-start' | 'center' | 'flex-end' | 'space-between' }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || '0'};
  align-items: ${(props) => props.alignItems || 'inherit'};
`;

export const FullBorder = styled.div<{ margin?: string }>`
  width: calc(100% + 40px);
  height: 1px;
  background-color: ${color.greyd4};
  margin: ${(props) => props.margin || '20px'} -20px;
`;

export const NormalBorder = styled.div<{ margin?: string }>`
  width: 100%;
  height: 1px;
  background-color: ${color.greyf4};
  margin: ${(props) => props.margin || '20px'} 0;
`;
