import { requestWithAuth } from 'utils/Axios-utils';

import { StoreCountryList, StoreCurrencyList } from 'features/Redux/slices/addressSlice';
import { Currency } from 'types/payout';

export const getCountryById = async (access_token: string, id: number) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/address/country/${id}/`,
    },
    access_token
  );
};

export const getAddressById = async (access_token: string, id: number) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/addresses/${id}/`,
    },
    access_token
  );
};

export const getAddressListByUser = async (access_token: string, id: number) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/addresses/?user__id=${id}`,
    },
    access_token
  );
};

export const getCountryList = async (dispatch: any, access_token: string) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/address/country/?korea=true`,
    },
    access_token
  ).then((data) => {
    dispatch(StoreCountryList(data));
    return data;
  });
};

export const getStateByCountry = async (access_token: string, countryId: number) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/address/state/${countryId}/`,
    },
    access_token
  );
};

export const getCityByState = async (access_token: string, stateId: number) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/address/city/${stateId}/`,
    },
    access_token
  );
};

export const postAddressToParticularUser = async (access_token: string, user_id: number, payload: any) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: `/accounts/addresses/`,
      data: { ...payload, user: user_id },
    },
    access_token
  );
};

export const patchAddress = async (access_token: string, address_id: number, payload: any) => {
  return await requestWithAuth(
    {
      method: 'patch',
      url: `/accounts/addresses/${address_id}/`,
      data: payload,
    },
    access_token
  );
};

export const deleteAddress = async (access_token: string, address_id: number) => {
  return await requestWithAuth(
    {
      method: 'delete',
      url: `/accounts/addresses/${address_id}/`,
    },
    access_token
  );
};

export const getCurrencyList = async (access_token: string, dispatch?: any) => {
  try {
    const data = await requestWithAuth<Currency[]>(
      {
        method: 'get',
        url: '/accounts/currency/',
      },
      access_token
    );

    if (dispatch) {
      dispatch(StoreCurrencyList(data));
    }
  } catch (error) {
    console.error('Failed to fetch currency list:', error);
  }
};
