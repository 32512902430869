import { lazy } from 'react';

export const LoginPage = lazy(() => import('pages/Account'));
export const MailAuthPage = lazy(() => import('pages/Account/MailAuth'));

export const BannerListPage = lazy(() => import('pages/Banner/List'));
export const BannerDetailPage = lazy(() => import('pages/Banner/Detail'));
export const NewBannerPage = lazy(() => import('pages/Banner/new'));
export const EditBannerPage = lazy(() => import('pages/Banner/Edit'));

export const ChatPage = lazy(() => import('pages/Chat/Chat'));
export const ChatAdminPage = lazy(() => import('pages/Chat/Admin'));

export const CampaignListPage = lazy(() => import('pages/Campaign/List'));
export const CampaignDetailPage = lazy(() => import('pages/Campaign/Detail'));
export const CampaignCreatePage = lazy(() => import('pages/Campaign/Create'));
export const CampaignEditPage = lazy(() => import('pages/Campaign/Edit'));
export const CampaignGuidePage = lazy(() => import('pages/Campaign/Guide'));

export const DashboardMainPage = lazy(() => import('pages/Dashboard/DashboardMain'));

export const SettingsMainPage = lazy(() => import('pages/Settings/SettingsMain'));

export const UsersMainPage = lazy(() => import('pages/Users/UsersMain'));
export const UserDetailPage = lazy(() => import('pages/Users/Detail'));
export const UserChargeHistoryPage = lazy(() => import('pages/Users/ChargeHistory'));
export const UserSpendHistoryPage = lazy(() => import('pages/Users/SpendHistory'));
export const EditUserPage = lazy(() => import('pages/Users/Edit'));
export const NewUserPage = lazy(() => import('pages/Users/New'));

export const ClientListPage = lazy(() => import('pages/Client/List'));
export const ClientDetailPage = lazy(() => import('pages/Client/Detail'));
export const EditClientPage = lazy(() => import('pages/Client/Edit'));
export const NewClientPage = lazy(() => import('pages/Client/New'));
export const ClientChargeHistoryPage = lazy(() => import('pages/Client/ChargeHistory'));
export const ClientSpendHistoryPage = lazy(() => import('pages/Client/SpendHistory'));
export const ClientOrderHistoryPage = lazy(() => import('pages/Client/OrderHistory'));

export const MyJellyPage = lazy(() => import('pages/MyJelly'));
export const JellyChargeHistoryPage = lazy(() => import('pages/MyJelly/ChargeHistory'));
export const JellySpendHistoryPage = lazy(() => import('pages/MyJelly/SpendHistoy'));
export const PackageSettingPage = lazy(() => import('pages/MyJelly/PackageSetting'));
export const OrderHistoryPage = lazy(() => import('pages/MyJelly/OrderHistory'));
export const AdminOrderHistoryPage = lazy(() => import('pages/MyJelly/AdminOrderHistory'));

export const PayoutRequestListPage = lazy(() => import('pages/Payout/List'));
export const PayoutRequestDetailPage = lazy(() => import('pages/Payout/Detail'));

export const NoticeListPage = lazy(() => import('pages/Notice/List'));
export const NoticeDetailPage = lazy(() => import('pages/Notice/Detail'));
export const EditNoticePage = lazy(() => import('pages/Notice/Edit'));
export const NewNoticePage = lazy(() => import('pages/Notice/New'));

export const AnalyticsPage = lazy(() => import('pages/Analytics'));

export const NotificationsListPage = lazy(() => import('pages/Notifications/List'));
export const NotificationsCreatePage = lazy(() => import('pages/Notifications/Create'));
export const NotificationsDetailPage = lazy(() => import('pages/Notifications/Detail'));
export const NotificationsEditPage = lazy(() => import('pages/Notifications/Edit'));
