import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import App from 'pages/App';
import { AppProviders } from 'providers/app-providers';
import ReactDOM from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';

dayjs.extend(relativeTime);

polyfillCountryFlagEmojis();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <AppProviders>
    <App />
  </AppProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
