import { requestWithAuth } from 'utils/Axios-utils';

import { SendbirdGroupChat } from '@sendbird/chat/groupChannel';
import { UpdateUser } from 'features/Redux/slices/accountSlice';
import { PointHistoryDetail } from 'types/Jelly';
import { PaginationType } from 'types/common';
import { UserDetails } from 'types/account';

export const getUserApi = async (access_token: string, dispatch: any) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/user/`,
    },
    access_token
  ).then((data) => {
    dispatch(UpdateUser(data));
    return data;
  });
};

export const getOtherUserProfile = async (access_token: string, username: string) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/profile/${username}/`,
    },
    access_token
  );
};

export const editProfile = async (formData: FormData, access_token: string) => {
  return await requestWithAuth<UserDetails>(
    {
      method: 'patch',
      url: `/accounts/user/`,
      data: formData,
      'Content-Type': 'multipart/form-data',
    },
    access_token
  );
};

export const completeTutorialApi = async (access_token: string) => {
  return await requestWithAuth(
    {
      method: 'patch',
      url: `/accounts/user/`,
      data: { complete_tutorial: true },
    },
    access_token
  );
};

export const getUserList = async (
  access_token: string,
  payload: {
    page?: number;
    page_size?: number;
    ordering?: string;
    search?: string;
    account_type?: number;
    type?: 'all' | 'member' | 'creator' | 'verified' | 'rejected' | 'partner';
    phone_code?: string;
    branch?: number;
  }
) => {
  const { page, page_size, ordering, search, account_type, type, branch } = payload;
  const userAccountType = {
    all: '',
    member: '1',
    creator: '3',
    verified: '6',
    rejected: '4',
    partner: '5',
  };
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/userlist/?page_size=${page_size || 25}&page=${page || 1}${ordering ? `&search=${ordering}` : ''}${
        search ? `&search=${search}` : ''
      }${
        account_type
          ? `&account_type=${account_type}`
          : type === 'all'
          ? ''
          : `&account_type=${userAccountType[type as 'member' | 'creator' | 'verified' | 'rejected' | 'partner']}`
      }${branch ? `&branch=${branch}` : ''}`,
    },
    access_token
  );
};

export const createInfluencer = async (access_token: string, payload: any, sendbird?: SendbirdGroupChat) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: `/accounts/user/influencer/`,
      data: payload,
      'Content-Type': 'multipart/form-data',
    },
    access_token
  ).then(async (data) => {
    const { profile, username, nickname } = data;
    if (sendbird) {
      await sendbird.disconnect();
      await sendbird
        .connect(username)
        .then(() => {
          sendbird.disconnect();
        })
        .catch((err) => {
          throw { response: { data: 'Sendbird Error.' } };
        });
    }
    return data;
  });
};

export const getInfluencerDetail = async (access_token: string, id: number) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/user/influencer/${id}/`,
    },
    access_token
  );
};

export const editInfluencerDetail = async (access_token: string, id: number, payload: any) => {
  return await requestWithAuth(
    {
      method: 'patch',
      url: `/accounts/user/influencer/${id}/`,
      data: payload,
      'Content-Type': 'multipart/form-data',
    },
    access_token
  );
};

export const deleteInfluencer = async (access_token: string, id: number) => {
  return await requestWithAuth(
    {
      method: 'delete',
      url: `/accounts/user/influencer/${id}/`,
    },
    access_token
  );
};

export const getPartnerList = async (
  access_token: string,
  payload: {
    page?: number;
    page_size?: number;
    ordering?: string;
    search?: string;
    account_type?: number;
    type?: 'waiting' | 'business' | 'rejected' | 'all';
  }
) => {
  const { page, page_size, ordering, search, account_type, type } = payload;
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/user/business/?page_size=${page_size || 25}&page=${page || 1}${
        ordering ? `&search=${ordering}` : ''
      }${search ? `&search=${search}` : ''}
    ${
      account_type
        ? `&account_type=${account_type}`
        : type === 'waiting'
        ? '&account_type=1'
        : type === 'business'
        ? '&account_type=2'
        : type === 'rejected'
        ? '&account_type=4'
        : ''
    }`,
    },
    access_token
  );
};

export const getPartnerDetail = async (access_token: string, id: number) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/user/business/${id}/`,
    },
    access_token
  );
};

export const editPartner = async (access_token: string, id: number, payload: any) => {
  return await requestWithAuth(
    {
      method: 'patch',
      url: `/accounts/user/business/${id}/`,
      data: payload,
      'Content-Type': 'multipart/form-data',
    },
    access_token
  );
};

export const createPartner = async (access_token: string, payload: any, sendbird?: SendbirdGroupChat) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: `/accounts/user/business/`,
      data: payload,
      'Content-Type': 'multipart/form-data',
    },
    access_token
  ).then(async (data) => {
    const { profile, username, nickname } = data;
    if (sendbird) {
      await sendbird.disconnect();
      await sendbird
        .connect(username)
        .then(() => {
          sendbird.disconnect();
        })
        .catch(() => {
          throw { response: { data: 'Sendbird Error.' } };
        });
    }
    return data;
  });
};

export const deletePartner = async (access_token: string, id: number) => {
  return await requestWithAuth(
    {
      method: 'delete',
      url: `/accounts/user/business/${id}/`,
    },
    access_token
  );
};

export const userApplyHistory = async (
  access_token: string,
  user_id: number,
  category: 'spend' | 'earn' | 'jelly',
  payload: {
    page?: number;
    page_size?: number;
  }
) => {
  const { page, page_size } = payload;
  return await requestWithAuth(
    {
      method: 'get',
      url: `/lounge/state/list/?user__id=${user_id}&content_type__model=${
        category === 'spend' ? 'event' : category === 'earn' ? 'quest' : 'market'
      }&page=${page || 1}&page_size=${page_size || 10}`,
    },
    access_token
  );
};

export const getPointHistory = async (
  access_token: string,
  payload: {
    page?: number;
    page_size?: number;
    type?: 'charge' | 'spend';
    username?: string;
    user_detail?: boolean;
    from?: string;
    to?: string;
  }
) => {
  return await requestWithAuth<PaginationType<PointHistoryDetail>>(
    {
      method: 'get',
      url: `/accounts/pointhistory/?page=${payload.page || 1}&page_size=${payload.page_size || 10}${
        payload.type ? `&type=${payload.type}` : ''
      }${payload.username ? `&username=${payload.username}` : ''}${
        payload.user_detail ? `&user_detail=${payload.user_detail}` : ''
      }${payload.from ? `&from=${payload.from}` : ''}${payload.to ? `&to=${payload.to}` : ''}${
        payload.type === 'charge' ? '&ordering=-updated' : ''
      }&completed=true`,
    },
    access_token
  );
};

export const getCreditHistory = async (
  access_token: string,
  payload: {
    page?: number;
    page_size?: number;
    type?: 'charge' | 'spend';
    username?: string;
    user_detail?: boolean;
    from?: string;
    to?: string;
  }
) => {
  return await requestWithAuth<PaginationType<PointHistoryDetail>>(
    {
      method: 'get',
      url: `/accounts/credithistory/?page=${payload.page || 1}&page_size=${payload.page_size || 10}${
        payload.type ? `&type=${payload.type}` : ''
      }${payload.username ? `&username=${payload.username}` : ''}${
        payload.user_detail ? `&user_detail=${payload.user_detail}` : ''
      }${payload.from ? `&from=${payload.from}` : ''}${payload.to ? `&to=${payload.to}` : ''}&completed=true`,
    },
    access_token
  );
};

export const postPointHistory = async (
  access_token: string,
  payload: {
    extra: string;
    point: number;
    from_user: number;
    to_user: number;
  }
) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: `/accounts/pointhistory/`,
      data: payload,
    },
    access_token
  );
};

export const postCreditHistory = async (
  access_token: string,
  payload: {
    extra: string;
    point: number;
    from_user: number;

    to_user: number;
  }
) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: `/accounts/credithistory/`,
      data: payload,
    },
    access_token
  );
};

export const getUserAnalytics = async (
  access_token: string,
  params: {
    date_from?: string;
    date_to?: string;
    is_admin: boolean;
  }
) => {
  return await requestWithAuth(
    {
      method: 'get',
      url: `/accounts/users/statistics/?${`is_admin=${params.is_admin}`}${
        params.date_from ? `&date_from=${params.date_from}` : ''
      }${params.date_to ? `&date_to=${params.date_to}` : ''}`,
    },
    access_token
  );
};
